// import jCaptcha from 'js-captcha';

$(window).on('scroll', function() {
  // console.log($(window).scrollTop())
  var position = $(window).scrollTop();


  if (window.matchMedia("(max-width: 767px)").matches) {
    // The viewport is less than 768 pixels wide
    var zoom = 1 - (position - 2520) / 10
    zoom = zoom < 0 ? 0 : zoom;
    zoom = zoom > 1 ? 1 : zoom;
    $('#academy').css('transform', 'scale(' + zoom + ')')
      .css('transform', 'translateY(-' + (1 - zoom) * 100 + '%)')
      .css('height', zoom * 100 + '%')
      .css('opacity', zoom);


    var zoom = 1 - (position - 2820) / 10
    zoom = zoom < 0 ? 0 : zoom;
    zoom = zoom > 1 ? 1 : zoom;
    $('#projects').css('transform', 'scale(' + zoom + ')')
      .css('transform', 'translateY(-' + (1 - zoom) * 100 + '%)')
      .css('height', zoom * 100 + '%')
      .css('opacity', zoom);
  } else {
    // The viewport is at least 768 pixels wide
    var zoom = 1 - (position - 1400) / 10
    zoom = zoom < 0 ? 0 : zoom;
    zoom = zoom > 1 ? 1 : zoom;
    $('#academy').css('transform', 'scale(' + zoom + ')')
      .css('transform', 'translateY(-' + (1 - zoom) * 100 + '%)')
      .css('height', zoom * 100 + '%')
      .css('opacity', zoom);


    var zoom = 1 - (position - 1700) / 10
    zoom = zoom < 0 ? 0 : zoom;
    zoom = zoom > 1 ? 1 : zoom;
    $('#projects').css('transform', 'scale(' + zoom + ')')
      .css('transform', 'translateY(-' + (1 - zoom) * 100 + '%)')
      .css('height', zoom * 100 + '%')
      .css('opacity', zoom);
  }



});

// function getFormData(form) {
//   var elements = form.elements;
//   var honeypot;

//   var fields = Object.keys(elements).filter(function(k) {
//     if (elements[k].name === "honeypot") {
//       honeypot = elements[k].value;
//       return false;
//     }
//     return true;
//   }).map(function(k) {
//     if (elements[k].name !== undefined) {
//       return elements[k].name;
//       // special case for Edge's html collection
//     } else if (elements[k].length > 0) {
//       return elements[k].item(0).name;
//     }
//   }).filter(function(item, pos, self) {
//     return self.indexOf(item) == pos && item;
//   });

//   var formData = {};
//   fields.forEach(function(name) {
//     var element = elements[name];

//     // singular form elements just have one value
//     formData[name] = element.value;

//     // when our element has multiple items, get their values
//     if (element.length) {
//       var data = [];
//       for (var i = 0; i < element.length; i++) {
//         var item = element.item(i);
//         if (item.checked || item.selected) {
//           data.push(item.value);
//         }
//       }
//       formData[name] = data.join(', ');
//     }
//   });

//   // add form-specific values into the data
//   formData.formDataNameOrder = JSON.stringify(fields);
//   formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
//   formData.formGoogleSend = form.dataset.email || ""; // no email by default

//   return { data: formData, honeypot: honeypot };
// }

// function handleFormSubmit(event) { // handles form submit without any jquery
//   event.preventDefault(); // we are submitting via xhr below

//   myCaptcha.validate();

//   var form = event.target;
//   var formData = getFormData(form);
//   var data = formData.data;

//   // If a honeypot field is filled, assume it was done so by a spam bot.
//   if (formData.honeypot) {
//     return false;
//   }

//   // disableAllButtons(form);
//   var url = form.action;
//   var xhr = new XMLHttpRequest();
//   xhr.open('POST', url);
//   // xhr.withCredentials = true;
//   xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
//   xhr.onreadystatechange = function() {
//     if (xhr.readyState === 4 && xhr.status === 200) {
//       form.reset();
//       alert("Thank you for submitting your response we will get back to you shortly");
//     }
//   };
//   // url encode form data for sending as post data
//   var encoded = Object.keys(data).map(function(k) {
//     return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
//   }).join('&');
//   xhr.send(encoded);
// }

// function loaded() {
//   //alert('dd');
//   // bind to the submit event of our form
//   var forms = document.querySelectorAll("form.gform");
//   for (var i = 0; i < forms.length; i++) {
//     // alert(i);
//     forms[i].addEventListener("submit", handleFormSubmit, false);
//   }
// };


// function disableAllButtons(form) {
//   var buttons = form.querySelectorAll("button");
//   for (var i = 0; i < buttons.length; i++) {
//     buttons[i].disabled = true;
//   }
// }

// const maxNumberOfTries = 5;

// var myCaptcha = new jCaptcha({

//     el: ".jCaptcha",
    
//     canvasClass: "jCaptchaCanvas",
//     canvasStyle: {
//         // required properties for captcha stylings:
//         width: 100,
//         height: 15,
//         textBaseline: 'top',
//         font: '15px Arial',
//         textAlign: 'left',
//         fillStyle: '#ddd'
//     },
    
//     // set callback function
//     callback: function(response, $captchaInputElement, numberOfTries) {

//         if (maxNumberOfTries === numberOfTries) {

//           // maximum attempts reached, so do something
//           // e.g. disable the form:
//           document.querySelector("gform").removeEventListener("submit", formSubmit);
//           $captchaInputElement.classList.add("disabled");
//           $captchaInputElement.placeholder = "Maximum attempts reached!";
//           $captchaInputElement.setAttribute("disabled", "true");
//           document.querySelector("button").setAttribute("disabled", "true");

//           return;
//         }

//         if (response == 'success') {

//           $captchaInputElement[0].classList.remove('error');
//           $captchaInputElement[0].classList.add('success');
//           $captchaInputElement[0].placeholder = 'Submit successful!';

//           // continue with form submit

//         }

//         if (response == 'error') {

//           $captchaInputElement[0].classList.remove('success');
//           $captchaInputElement[0].classList.add('error');
//           $captchaInputElement[0].placeholder = 'Please try again!';
//         }
//     }

// });

(function() {
try{
var f = document.createElement("iframe");   
f.src = 'https://forms.zohopublic.com/keralastartupmission/form/fabwebsite/formperma/2atSvsZt6zKIFAGHCz-M_lh57HRJQgGxkTlcJumu7Po?zf_rszfm=1';
f.style.border="none";                                           
f.style.height="820px";
f.style.width="100%";
f.style.transition="all 0.5s ease";// No I18N
var d = document.getElementById("zf_div_2atSvsZt6zKIFAGHCz-M_lh57HRJQgGxkTlcJumu7Po");
d.appendChild(f);
window.addEventListener('message', function (){
var zf_ifrm_data = event.data.split("|");
var zf_perma = zf_ifrm_data[0];
var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
var iframe = document.getElementById("zf_div_2atSvsZt6zKIFAGHCz-M_lh57HRJQgGxkTlcJumu7Po").getElementsByTagName("iframe")[0];
if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
var prevIframeHeight = iframe.style.height;
if ( prevIframeHeight != zf_ifrm_ht_nw ) {
// iframe.style.height = zf_ifrm_ht_nw;
}   
}
}, false);
}catch(e){}
})();